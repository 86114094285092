<template>
    <div class="loginTop">
        <p>Remote Support</p>
    </div>
    <h3>{{ $t('login') }}</h3>
    <div class="loginContent">
        <div class="alert alert-danger" role="alert" v-if="serverError">
            {{ $t(serverError) }}
        </div>
        <form v-on:submit.prevent="onLogin()">
            <div class="mb-3 email">
                <label for="email">{{ $t('email') }}</label>
                <input class="form-control" type="text" id="email" :placeholder="$t('email')" v-model.trim="email">
                <p class="error" v-if="errors.email">{{ $t(errors.email) }}</p>
            </div>
            <div class="mb-3 password">
                <label for="password">{{ $t('password') }}</label>
                <input class="form-control" :type="passwordType" id="password" :placeholder="$t('password')"
                    v-model.trim="password" autocomplete="off">
                <p class="error" v-if="errors.password">{{ $t(errors.password) }}</p>
                <span class="eye"><i v-on:click="switchVisibility()" :class="faClass" aria-hidden="true"></i></span>
                <p class="forgot"><router-link to="forgot-password">{{ $t('forgot-password') }}</router-link></p>
            </div>
            <p class="text-center"><button class="btn" type="submit" data-toggle="modal" data-target="#exampleModal">{{
                $t('login') }}</button></p>
        </form>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import LoginValidation from '@/services/LoginValidation'
import { LOGIN_USER } from '@/store/storeconstants'
export default {
  title () {
    return `Remote Assistance | ${this.title}`
  },
    data(){
        return{
            title: 'Login',
            passwordType: 'password',
            faClass: 'fa fa-eye-slash',
            email: '',
            password: '',
            errors: [],
            serverError: '',
        }
    },
    methods: {
        ...mapActions('auth',{
            login: LOGIN_USER,
        }),
        switchVisibility(){
            this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
            this.faClass = this.faClass === 'fa fa-eye-slash' ? 'fa fa-eye' : 'fa fa-eye-slash';
        },
        async onLogin(){


            this.serverError = '';
            let validations = new LoginValidation(this.email, this.password)
            this.errors = validations.checkVelidations();
            if('email' in this.errors || 'password' in this.errors){
                return false;
            }
            try{
                await this.login({email: this.email, password: this.password});
            }catch(e){
                this.serverError = e;
                return false;
            }
            this.$router.push('/organizations');
        }
    }
}
</script>